import React, { FC } from "react"
import { palette, ThemeColors } from "../theme/color"

export type DisplayOptions = "inline" | "flex" | "block" | "none"
export type FlexDirections = "row" | "column" | "row-reverse" | "column-reverse"
export type JustifyContent = "space-between" | "space-around" | "center" | "flex-start" | "flex-end"
export type AlignItems = "flex-start" | "flex-end" | "center" | "stretch" | "baseline"
type FlexViewProps = {
  display?: DisplayOptions
  flexDirection?: FlexDirections
  alignItems?: AlignItems
  justifyContent?: JustifyContent
  centerAll?: boolean
  backgroundColor?: ThemeColors
  children?: React.ReactNode
  style?: React.CSSProperties
}

export const FlexView: FC<FlexViewProps> = ({
  display = "flex",
  flexDirection = "row",
  alignItems,
  justifyContent,
  centerAll = false,
  backgroundColor,
  style,
  children,
}) => {
  return (
    <div
      style={{
        ...(display && { display }),
        ...(flexDirection && { flexDirection }),
        ...(backgroundColor && { backgroundColor: palette[backgroundColor] }),
        ...(alignItems && { alignItems }),
        ...(justifyContent && { justifyContent }),
        ...(centerAll && { alignItems: "center", justifyContent: "center" }),
        ...style,
      }}>
      {children}
    </div>
  )
}
