import React from "react"
import { createBrowserRouter } from "react-router-dom"
import { Landing } from "./Landing/Landing"

// Define routes
export const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
])
