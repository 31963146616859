import React, { FC, useEffect, useRef, useState } from "react"
import { FlexView } from "../../components/FlexView"
import { Text } from "../../components/Text"

const TEXTS = [
  "",
  "w",
  "ww",
  "www",
  "www.",
  "www.G",
  "www.Gr",
  "www.Grg",
  "www.Grge",
  "www.Grge",
  "www.Grg",
  "www.Grg",
  "www.Gr",
  "www.Gre",
  "www.Greg",
  "www.GregB",
  "www.GregBr",
  "www.GregBri",
  "www.GregBrin",
  "www.GregBrink",
  "www.GregBrinke",
  "www.GregBrinker",
  "www.GregBrinker.",
  "www.GregBrinker.c",
  "www.GregBrinker.co",
  "www.GregBrinker.com",
]

export const Landing: FC = () => {
  const [textIndex, setTextIndex] = useState<number>(0)
  const [dashVisible, setDashVisible] = useState<boolean>(true)
  const hasStarted = useRef<boolean>(false)

  const nextText = () => {
    // Stop at the last one
    if (textIndex === TEXTS.length - 1) {
      return
    }
    setTextIndex(prev => prev + 1)

    // Go to the next
    setTimeout(nextText, Math.random() * 150 + 350)
  }

  useEffect(() => {
    if (!hasStarted.current) {
      hasStarted.current = true
      setTimeout(() => {
        nextText()
      }, 2000)
    }

    const dashTimeoutRef = setInterval(() => {
      setDashVisible(prev => !prev)
    }, 500)

    return () => {
      console.log("RETURNING")
      setTextIndex(0)
      clearInterval(dashTimeoutRef)
    }
  }, [])

  return (
    <FlexView backgroundColor="indigoDye" centerAll style={{ width: "100%", height: "100%" }}>
      <FlexView display="flex" flexDirection="row" alignItems="flex-end">
        <Text text={TEXTS[textIndex] ?? TEXTS[TEXTS.length - 1]} color="white" fontWeight={300} fontSize={40} />
        <Text text={"_"} color="white" fontWeight={300} fontSize={40} style={{ opacity: dashVisible ? 1 : 0 }} />
      </FlexView>
    </FlexView>
  )
}
