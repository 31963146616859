export const palette = {
  white: "#FFFFFF",
  black: "#000000",

  // Theme colors
  prussianBlue: "#153243",
  indigoDye: "#284B63",
  amaranthPink: "#D999B9",
  puce: "#D17B88",
  columbiaBlue: "#BBDEF0",
}
export type ThemeColors = keyof typeof palette
