import React, { FC } from "react"
import { palette, ThemeColors } from "../theme/color"

type TextProps = {
  text?: string
  fontWeight?: number // "regular" | "bold"
  fontSize?: number // "tiny" | "small" | "medium" | "large"
  color?: ThemeColors
  style?: React.CSSProperties
}

export const Text: FC<TextProps> = ({ text, fontWeight, fontSize, color = "black", style }) => {
  return <span style={{ fontFamily: "DMSans", fontWeight, fontSize, color: palette[color], ...style }}>{text}</span>
}
